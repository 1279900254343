export const loadScript = (src) => {
    return new Promise ((resolve,reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            reject(false)
        }
        document.body.appendChild(script);
    })
}

export const utmParams = (queryString) => {
    let urlParams = new URLSearchParams(queryString);
    if(!urlParams.get('utm_source')) {
        return urlParams = 'utm_source=organic'
    } else {
        return urlParams = urlParams.toString();
    }
}

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }