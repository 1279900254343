import React, {useState} from 'react';
import Layout from '../components/layout';
import {navigate} from 'gatsby';
import paymentMethods from '../../static/images/payment-methods.png';
import '../styles/pay.css';
import {Field, Form, Formik} from "formik"
import * as Yup from "yup";
import {loadScript} from '../utils/Helper';

const PaymentFormSchema = Yup.object().shape({
    email: Yup.string().required("Please enter a valid email").email("Please enter a valid email"),
    name: Yup.string().required("Please enter your full name"),
    mobile: Yup.number().required("Please enter a valid number").positive("Please enter a valid number").integer("Please enter a valid number"),
    course: Yup.string().required("Please select a value from the dropdown"),
    amount: Yup.number().required('Minimum value INR 500.00').min(500)
  })

const Pay = () => {

    const [spinner, setSpinner] = useState(false);

    async function displayRazorpay (data) {

        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    
        if(!res) {
            alert('Razorpay SDK failed to load');
            setSpinner(false);
            return
        }
    
        const liveKey = 'rzp_live_o8v6zL5Hf0PJMt';
    
        const options = {
            key: liveKey,
            currency: data.currency,
            amount: data.amount,
            order_id: data.orderId,
            name: data.studentName,
            image: "https://www.crampete.com/images/logo.svg",
            handler: function (response){
    
              const opts = {
                  txnId: data.txnId,
                  companyCode: 'crampete',
                  paymentId: response.razorpay_payment_id,
                  courseName: data.courseName,
                  amount: data.amount,
                  studentName: data.studentName
              }

                fetch('https://6rola3qg3l.execute-api.ap-south-1.amazonaws.com/v1/studentpay', {
                method: 'post',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(opts)
                }).then(function(response) {
                return response.json();
                }).then(function(res) {
                    if(res.success) {
                        navigate('/payment-receipt/?txnId='+res.data.txnId+'&course='+res.data.course+'&amount='+res.data.amount+'&name='+res.data.studentName);
                    }
                }).catch(err => console.error(err) );
            },
            prefill: {
                name: data.studentName,
                email: data.studentEmail,
            },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    const handlePay = values => {
        
        setSpinner(true);

        const {email, name, mobile, course, amount} = values;
        const opts = {
            companyCode: 'crampete',
            studentName: name,
            studentEmail: email,
            studentMobile: mobile,
            courseName: course,
            amount
          }
      
          let query = Object.keys(opts)
                   .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(opts[k]))
                   .join('&');

        fetch('https://6rola3qg3l.execute-api.ap-south-1.amazonaws.com/v1/studentpay?'+query, {
        method: 'get',
        headers: {
            "Content-Type": "application/json"
        }
        }).then(function(response) {
        return response.json();
        }).then(function(res) {
            if(res.success) {
                return displayRazorpay(res.data)
            }
        }).catch(error => {
            console.error('There was an error!', error);
            setSpinner(false);
        });;
    }

    return (
        <Layout title="Crampete | Payments" isLanding={true}>
            <main id="crampetePaymentsPage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="crampetePaymentsPage__leftWrapper">
                                <h1>Crampete's Payments Page</h1>
                                <div className="crampetePaymentsPage__leftWrapper__content">
                                    <h2>Courses offered</h2>
                                    <ul>
                                        <li>Full Stack Web Development</li>
                                        <li>Data Science</li>
                                        <li>Digital Marketing</li>
                                        <li>Frontend Development</li>
                                        <li>Backend Development</li>
                                        <li>Python</li>
                                        <li>Workshop</li>
                                    </ul>
                                </div>
                                <div className="crampetePaymentsPage__leftWrapper__content">
                                    <h2>Organizer</h2>
                                    <p><strong>Crampete | Your own learning app</strong></p>
                                    <address>New #132 Habibullah Road, Thiyagaraya Nagar, <br/>Near Karanataka Sangha and Opposite to Sharma Park,<br/>Chennai, Tamil Nadu 600017</address>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="crampetePaymentsPage__rightWrapper">
                                <div className="crampetePaymentsPage__formWrapper">
                                <Formik initialValues={{email: "", name: "", mobile: "", course: "", amount: ""}} validationSchema={PaymentFormSchema} onSubmit={handlePay} >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <div className={`${touched.name && errors.name ? 'payFieldError' :''}`}>
                                                <Field title={`${touched.name && errors.name ? errors.name :''}`} autoComplete="off" name="name" placeholder="Full Name" />
                                            </div>
                                            <div className={`${touched.email && errors.email ? 'payFieldError' :''}`}>
                                                <Field title={`${touched.email && errors.email ? errors.email :''}`} autoComplete="off" name="email" placeholder="Email Address" />
                                            </div>
                                            <div className={`${touched.mobile && errors.mobile ? 'payFieldError' :''}`}>
                                                <Field title={`${touched.mobile && errors.mobile ? errors.mobile :''}`} autoComplete="off" name="mobile" placeholder="Mobile Number" />
                                            </div>
                                            <div className={`${touched.course && errors.course ? 'payFieldError' :''}`}>
                                                <Field title={`${touched.course && errors.course ? errors.course :''}`} as="select" name="course">
                                                    <option value="" disabled>Select Course</option>
                                                    <option value="Full Stack Web Development">Full Stack Web Development</option>
                                                    <option value="Data Science">Data Science</option>
                                                    <option value="Digital Marketing">Digital Marketing</option>
                                                    <option value="Frontend Development">Frontend Development</option>
                                                    <option value="Backend Development">Backend Development</option>
                                                    <option value="Python">Python</option>
                                                    <option value="Workshop">Workshop</option>
                                                </Field>
                                            </div>
                                            <div className={`${touched.amount && errors.amount ? 'payFieldError' :''}`}>
                                                <Field title={`${touched.amount && errors.amount ? errors.amount :''}`} autoComplete="off" name="amount" placeholder="Amount" />
                                            </div>
                                            <div>
                                                { spinner ? <button disabled={true}>Please wait...</button> : <button type="submit">Pay Now</button> }
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                                </div>
                                <div style={{"maxWidth":"300px","margin":"0 auto"}}>
                                    <small>On clicking Pay Now, you will be redirected to the payment gateway page</small>
                                </div>
                                <div className="crampetePaymentsPage__paymentsMethods">
                                    <img src={paymentMethods} alt="Crampete Payment Methods"/>
                                </div>
                                <div style={{"maxWidth":"450px","margin":"0 auto"}}>
                                    <small style={{"fontStyle":"italic"}}>Note:You will receive a receipt within 24 hours from Brainvalley Software Private Limited who is the authorised partner of Crampete Learning Centre.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default Pay